import React from 'react';

import MenuItem from './MenuItem';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faHouse } from '@fortawesome/free-solid-svg-icons'
import SearchBar from './SearchBar';


const Empfehlung = (props) => {
    return (
        <div className="empfehlung">
            <Container>                
                <Card
                    style={
                        {
                            backgroundColor: props.bg,
                            border: "none",
                            margin: "10px 0"
                        }
                    }
                    className=""
                    >
                    <Card.Body>
                    <Container>
                        <a href="">
                        <p className="powered">powered by</p>
                            <Row>
                                <Col style={{textAlign: 'center'}}>
                                    <img src="img/ratiopharm.jpg" width="70%" />
                                </Col>
                            </Row>
                        </a>
                    </Container>
                    </Card.Body>
                </Card>
            </Container>
        </div>        
    )
}

export default Empfehlung;