import React from 'react';

import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';



const PushNews = (props) => {
    return (
        <div className="openingHours">
            <Container>
                <Card
                    style={
                        {
                            backgroundColor: props.bg,
                            border: "none",
                            margin: "10px 0"
                        }
                    }
                    className=""
                    >
                    <Card.Body>
                        <Container>
                        <h3>Öffnungszeiten</h3>
                      {/*  <p>Jetzt geöffnet: 08:00 Uhr - 18:30 Uhr</p> */}
                            <Table responsive>
                                <tbody>
                                    <tr>
                                        <td className="day" >Montag</td>
                                        <td className="time" >08:00 - 20:00 Uhr</td>
                                    </tr>
                                    <tr>
                                        <td className="day" >Dienstag</td>
                                        <td className="time" >08:00 - 20:00 Uhr</td>
                                    </tr>
                                    <tr>
                                        <td className="day" >Mittwoch</td>
                                        <td className="time" >08:00 - 20:00 Uhr</td>
                                    </tr>
                                    <tr>
                                        <td className="day" >Donnerstag</td>
                                        <td className="time" >08:00 - 20:00 Uhr</td>
                                    </tr>
                                    <tr>
                                        <td className="day" >Freitag</td>
                                        <td className="time" >08:00 - 20:00 Uhr</td>
                                    </tr>
                                    <tr>
                                        <td className="day" >Samstag</td>
                                        <td className="time" >08:00 - 20:00 Uhr</td>
                                    </tr>
                                    
                                </tbody>
                            </Table>
                        </Container>
                    </Card.Body>
                </Card>
            </Container>
            
        </div>        
    )
}

export default PushNews;

