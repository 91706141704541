import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faHouse, faCartShopping } from '@fortawesome/free-solid-svg-icons'

const MenuItem = (props) => {
    const iconText = props.icon;

    const icons = [
        'img/icon_b_home.png',
        'img/icon_b_shop.png',
        'img/icon_b_rezept.png',
        'img/icon_b_beratung.png',
        'img/icon_b_notdienst.png',
        'img/icon_b_pillen.png'
    ];

    return (
       <div className="menuItem">
            <a href={props.link}>
                <div><img src={icons[props.icon]} /></div>
                <div>{props.title}</div>
            </a>
       </div>
    )
}

export default MenuItem;