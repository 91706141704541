
import MainButton from './../components/MainButton';
import FooterMenu from './../components/FooterMenu';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './../components/Header';
import OpeningHours from './../components/OpeningHours';
import Kontakt from './../components/Kontakt';
import Empfehlung from './../components/Empfehlung';


const Datenschutz= () => {

    
    return (
      <div className="App">
        <Container>
          <Row>
            <Header />
          </Row>
          <Empfehlung />
          <Row>
            <p>HALLO</p>    
          </Row>
          <Row>
            <Col xs="6">
              <MainButton title="Termine" bg="#1f8f3a" dynClass="icon" image={6}/>
            </Col>      
            <Col xs="6">
            </Col>     
          </Row>
          <Row>
            <Col xs="6">
            </Col>      
            <Col xs="6">
            </Col>     
          </Row>
        </Container>     
        
        <OpeningHours />
        <Kontakt />
        <footer>
          <FooterMenu />
        </footer>
      </div>
    );
  }
  
  export default Datenschutz;
  