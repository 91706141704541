import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';

const MainButton = (props) => {
    const images = [
        'img/qr.svg',
        'img/icon_beratung.png',
        'img/einkaufswagen.svg',
        'img/icon_rezept.png',
        'img/coupons.svg',
        'img/jobs.svg',
        'img/termine.svg'

    ];


    return (
        
        <Card
            style={
                {
                    backgroundColor: props.bg,
                    border: "none",
                    margin: "10px 0px"
                }
            }
            className="mainButton"
            >
            <a target="_blank" href={props.link}>
                <Card.Body>
    
                    <Container>
                            <Row>
                                <Col>
                                    <div className={props.dynClass}>
                                        <img src={images[props.image]} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col><p className="title">{props.title}</p></Col>
                            </Row>
                    </Container>
                
                </Card.Body>
            </a> 
        </Card>
        
    )
}

export default MainButton;